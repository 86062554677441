// Core
import React, {useEffect, useRef} from "react"

// Components
import { setCursorPos } from "../../components/cursor/set"

// Styles
import styles from "./cursor.module.scss"

const Cursor = () => {
  const animationFrame = useRef(null),
        cursor = useRef(null),
        cursorPos = useRef({
          prevX: 0,
          prevY: 0,
          x: 0,
          y: 0
        })

  const trackMouse = (e) => {
    cursorPos.current.x = e.clientX
    cursorPos.current.y = e.clientY
  }

  const render = () => {
    setCursorPos(cursor, cursorPos, .3)
    animationFrame.current = window.requestAnimationFrame(render)
  }

  useEffect(() => {
    window.addEventListener('mousemove', trackMouse)
    animationFrame.current = window.requestAnimationFrame(render)
    window.cursor = cursor.current

    return () => {
      window.removeEventListener('mousemove', trackMouse)
      animationFrame && window.cancelAnimationFrame(animationFrame.current)
    }
  }, [])

  return (
    <div className={styles.cursor} ref={cursor}>
      <div className={styles.hand}></div>
      <div className={styles.hold}>
        <span>H</span>
        <span>o</span>
        <span>l</span>
        <span>d</span>
        <span>&nbsp;</span>
        <span>t</span>
        <span>o</span>
        <span>&nbsp;</span>
        <span>c</span>
        <span>o</span>
        <span>n</span>
        <span>t</span>
        <span>i</span>
        <span>n</span>
        <span>u</span>
        <span>e</span>
      </div>
    </div>
  )
}

export default Cursor