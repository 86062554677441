const pages = [
  {
    index: 0,
    title: "Direct Democracy",
    slug: "direct-democracy",
    label:
      "This must be the place, not where power rests, but where it listens.",
  },
  {
    index: 1,
    title: "Powerful Communities",
    slug: "powerful-communities",
    label: "It’s here that we gather and where good gets done.",
  },
  {
    index: 2,
    title: "Living Locally",
    slug: "living-locally",
    label: "It is here that we play and share and give.",
  },
  {
    index: 3,
    title: "Dear Green Places",
    slug: "dear-green-places",
    label: "This must be the place where growth comes in green.",
  },
  {
    index: 4,
    title: "Technology and Nature",
    slug: "technology-and-nature",
    label: "This must be the place where nature soars and blooms.",
  },
  {
    index: 5,
    title: "Industrial Rewilding",
    slug: "industrial-rewilding",
    label: "This must be the place where the wild things can roam.",
  },
  {
    index: 6,
    title: "Sustainable Industry",
    slug: "sustainable-industry",
    label: "This must be the place where we create and recreate.",
  },
  {
    index: 7,
    title: "Out of office",
    slug: "out-of-office",
    label: "This must be the place where the office is a choice.",
  },
  {
    index: 8,
    title: "Our Home",
    slug: "our-home",
    label: "This must be the place we call home.",
  },
  {
    index: 9,
    title: "Credits",
    slug: "credits",
    label: "The people that made it possible.",
  },
]

export default pages
