// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-credits-index-js": () => import("./../../../src/pages/credits/index.js" /* webpackChunkName: "component---src-pages-credits-index-js" */),
  "component---src-pages-dear-green-places-index-js": () => import("./../../../src/pages/dear-green-places/index.js" /* webpackChunkName: "component---src-pages-dear-green-places-index-js" */),
  "component---src-pages-direct-democracy-index-js": () => import("./../../../src/pages/direct-democracy/index.js" /* webpackChunkName: "component---src-pages-direct-democracy-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrial-rewilding-index-js": () => import("./../../../src/pages/industrial-rewilding/index.js" /* webpackChunkName: "component---src-pages-industrial-rewilding-index-js" */),
  "component---src-pages-living-locally-index-js": () => import("./../../../src/pages/living-locally/index.js" /* webpackChunkName: "component---src-pages-living-locally-index-js" */),
  "component---src-pages-our-home-index-js": () => import("./../../../src/pages/our-home/index.js" /* webpackChunkName: "component---src-pages-our-home-index-js" */),
  "component---src-pages-out-of-office-index-js": () => import("./../../../src/pages/out-of-office/index.js" /* webpackChunkName: "component---src-pages-out-of-office-index-js" */),
  "component---src-pages-powerful-communities-index-js": () => import("./../../../src/pages/powerful-communities/index.js" /* webpackChunkName: "component---src-pages-powerful-communities-index-js" */),
  "component---src-pages-sustainable-industry-index-js": () => import("./../../../src/pages/sustainable-industry/index.js" /* webpackChunkName: "component---src-pages-sustainable-industry-index-js" */),
  "component---src-pages-technology-and-nature-index-js": () => import("./../../../src/pages/technology-and-nature/index.js" /* webpackChunkName: "component---src-pages-technology-and-nature-index-js" */)
}

