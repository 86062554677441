// Core
import React, {useContext} from "react"

// Context
import { Context } from "../../context"

// Components
import { setCursorState } from "../cursor/set"
import WideBtn from "../wideBtn"

// Styles
import styles from "./audioScreen.module.scss"

// Assets
import Logo from "../../pages/assets/logo.inline.svg"
import headphones from "./assets/headphones.png"

const AudioScreen = ({ hideAudioScreen, setHideAudioScreen }) => {
  const [state, setState] = useContext(Context)

  const setPlayAudio = (playAudio) => {
    setState(state => ({ ...state, playAudio: playAudio, audioScreenClicked: true }))
    setHideAudioScreen(true)
  }

  const activateAudio = (e) => {
    e.preventDefault()
    setPlayAudio(true)
  }

  const deactivateAudio = (e) => {
    e.preventDefault()
    setPlayAudio(false)
  }

  return (
    <div className={`${styles.audioScreen} ${(hideAudioScreen || state.audioScreenClicked) ? styles.hidden : ""}`}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.content}>
        <img src={headphones} width={78} height={64} alt={`Headphones`} />
        <p>This story is best told with sound</p>
        <WideBtn
          clickEvent={activateAudio}
          width={147}
          >
          <span>Okay, got it!</span>
        </WideBtn>
      </div>
      <button
        onClick={deactivateAudio}
        className={`text-button ${styles.secondaryButton}`}
        onMouseEnter={() => setCursorState('pointer', true)}
        onMouseLeave={() => setCursorState('pointer', false)}
        >
        Launch without sound
      </button>
    </div>
  )
}

export default AudioScreen