const distanceFromPrev = (x1, y1, x2, y2) => {
  let xs = x2 - x1,
      ys = y2 - y1

  xs *= xs
  ys *= ys

  return Math.sqrt(xs + ys)
}

const setCursorPos = (cursor, cursorPos, speed) => {
  let distance = distanceFromPrev(cursorPos.current.prevX, cursorPos.current.prevY, cursorPos.current.x, cursorPos.current.y)

  cursorPos.current.prevX += (cursorPos.current.x - cursorPos.current.prevX) * speed
  cursorPos.current.prevY += (cursorPos.current.y - cursorPos.current.prevY) * speed

  if (!cursor) return

  cursor.current.style.transform = `translateX(${cursorPos.current.prevX}px) translateY(${cursorPos.current.prevY}px)`
  cursor.current.setAttribute('data-moving', distance > 10)
}

const setCursorState = (state, val) => {
  window.cursor.setAttribute("data-" + state, val)
}

export { setCursorPos, setCursorState }