// Core
import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"

// Components
import { setCursorState } from "../cursor/set"

// Styles
import styles from "./wideBtn.module.scss"

// Assets
import Bg from "./assets/bg.inline.svg"
import NarrowBg from "./assets/narrowBg.inline.svg"
import Outline from "./assets/outline.inline.svg"
import Texture from "./assets/texture.png"
import NarrowTexture from "./assets/narrowTexture.png"

const WideBtn = ({
  children,
  to,
  url,
  clickEvent,
  exit,
  entry,
  label,
  fontSize = 16,
  width,
  height = 47,
  outline,
}) => {
  if (to) {
    return (
      <TransitionLink
        className={`button ${styles.wideBtn} ${
          outline ? styles.outline : null
        }`}
        onClick={clickEvent}
        onMouseEnter={() => setCursorState("pointer", true)}
        onMouseLeave={() => setCursorState("pointer", false)}
        aria-label={label}
        to={to}
        exit={
          exit
            ? exit
            : {
                length: 1,
                zIndex: 5,
              }
        }
        entry={
          entry
            ? entry
            : {
                delay: 0,
                zIndex: 10,
              }
        }
        style={{
          fontSize: fontSize + "px",
          height: height + "px",
          width: width + "px",
        }}
      >
        {!outline && width > 200 ? (
          <React.Fragment>
            <Bg />
            <img
              className={styles.texture}
              src={Texture}
              width={221}
              height={47}
              alt=""
            />
          </React.Fragment>
        ) : !outline ? (
          <React.Fragment>
            <NarrowBg />
            <img
              className={styles.texture}
              src={NarrowTexture}
              width={161}
              height={47}
              alt=""
            />
          </React.Fragment>
        ) : (
          <Outline />
        )}
        {children}
        <span
          className={`${styles.hoverCircle} ${styles.hoverCircleOutline}`}
        ></span>
      </TransitionLink>
    )
  } else {
    return (
      <a
        href={url}
        className={`button ${styles.wideBtn} ${
          outline ? styles.outline : null
        }`}
        onClick={clickEvent}
        onMouseEnter={() => setCursorState("pointer", true)}
        onMouseLeave={() => setCursorState("pointer", false)}
        aria-label={label}
        target="_blank"
        rel="noreferrer"
        style={{
          fontSize: fontSize + "px",
          height: height + "px",
          width: width + "px",
        }}
      >
        {!outline && width > 200 ? (
          <React.Fragment>
            <Bg />
            <img
              className={styles.texture}
              src={Texture}
              width={221}
              height={47}
              alt=""
            />
          </React.Fragment>
        ) : !outline ? (
          <React.Fragment>
            <NarrowBg />
            <img
              className={styles.texture}
              src={NarrowTexture}
              width={161}
              height={47}
              alt=""
            />
          </React.Fragment>
        ) : (
          <Outline />
        )}
        {children}
        <span className={styles.hoverCircle}></span>
      </a>
    )
  }
}

export default WideBtn
