// Core
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React, { useState, useRef, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

// Context
import { Context, Provider } from "../../context"

// Styles
import "./base.scss"

// Components
import SEO from "../seo"
import AudioScreen from "../audioScreen"
import Cursor from "../cursor"

// Assets
import DirectDemocracyImg from "./share-assets/direct-democracy.jpg"
import PowerfulCommunitiesImg from "./share-assets/powerful-communities.jpg"
import LivingLocallyImg from "./share-assets/living-locally.jpg"
import DearGreenPlacesImg from "./share-assets/dear-green-places.jpg"
import TechnologyAndNatureImg from "./share-assets/technology-and-nature.jpg"
import IndustrialRewildingImg from "./share-assets/industrial-rewilding.jpg"
import SustainabilityIndustryImg from "./share-assets/sustainability-industry.jpg"
import OutOfOfficeImg from "./share-assets/out-of-office.jpg"
import OurHomeImg from "./share-assets/our-home.jpg"

// Data
import pages from "../../data/pages"

const getSEOData = (pathname) => {
  switch(pathname) {
    case "/":
      return {
        title: "This Must Be The Place",
        description: "An interactive leap forward to a sustainable Scotland"
      }
      break;
    case "/direct-democracy/":
      return {
        title: pages[0].title,
        description: pages[0].label,
        image: DirectDemocracyImg
      }
      break;
    case "/powerful-communities/":
      return {
        title: pages[1].title,
        description: pages[1].label,
        image: PowerfulCommunitiesImg
      }
      break;
    case "/living-locally/":
      return {
        title: pages[2].title,
        description: pages[2].label,
        image: LivingLocallyImg
      }
      break;
    case "/dear-green-places/":
      return {
        title: pages[3].title,
        description: pages[3].label,
        image: DearGreenPlacesImg
      }
      break;
    case "/technology-and-nature/":
      return {
        title: pages[4].title,
        description: pages[4].label,
        image: TechnologyAndNatureImg
      }
      break;
    case "/industrial-rewilding/":
      return {
        title: pages[5].title,
        description: pages[5].label,
        image: IndustrialRewildingImg
      }
      break;
    case "/sustainable-industry/":
      return {
        title: pages[6].title,
        description: pages[6].label,
        image: SustainabilityIndustryImg
      }
      break;
    case "/out-of-office/":
      return {
        title: pages[7].title,
        description: pages[7].label,
        image: OutOfOfficeImg
      }
      break;
    case "/our-home/":
      return {
        title: pages[8].title,
        description: pages[8].label,
        image: OurHomeImg
      }
      break;
    case "/credits/":
      return {
        title: pages[9].title,
        description: pages[9].label
      }
      break;
    default:
      return {
        title: "This must be the place",
        description: "This must be the place"
      }
  }
}

const Layout = ({ children, location, scroll = true }) => {
  const [state, setState] = useContext(Context)

  const [hideAudioScreen, setHideAudioScreen] = useState(
    location.pathname === "/"
  )

  const seoData = getSEOData(location.pathname)

  const visited = useRef([])

  let keyMode = false

  const setMouseMode = () => {
    if (!keyMode) return
    keyMode = false
    document.body.classList.remove("key-access")
  }

  const setKeyboardMode = () => {
    if (keyMode) return
    keyMode = true
    document.body.classList.add("key-access")
  }

  useEffect(() => {
    document.body.addEventListener("mousedown", setMouseMode)
    document.body.addEventListener("keydown", setKeyboardMode)

    return () => {
      document.body.removeEventListener("mousedown", setMouseMode)
      document.body.removeEventListener("keydown", setKeyboardMode)
    }
  }, [])

  useEffect(() => {
    visited.current.push(location.pathname)

    if (visited.current.length > 1 && hideAudioScreen) {
      setHideAudioScreen(true)
      setState(state => ({ ...state, audioScreenClicked: true }))
    }
  }, [location.pathname])

  return (
    <React.Fragment>
      <SEO
        title={seoData.title}
        description={seoData.description}
        image={seoData.image}
        pathname={location.pathname}
      />
      <Provider>
        {!hideAudioScreen && (
          <AudioScreen
            hideAudioScreen={hideAudioScreen}
            setHideAudioScreen={setHideAudioScreen}
          />
        )}
        <div className="site-wrap">{hideAudioScreen && children}</div>
        <Cursor />
      </Provider>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
